import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { getAllTotalAirlineVouchers } from "../services/dashboard.services";
import { useLocation } from "react-router-dom";

const TotalAirlineVoucher: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const filter = location?.state?.filter;
  useEffect(() => {
    dispatch(getAllTotalAirlineVouchers(filter));
  }, []);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Total Airline Voucher List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <TotalAirlineVoucherGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TotalAirlineVoucherGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const TotalAirlineVoucherList = useAppSelector(
    (state) => state.dashboard.TotalAirlineVoucherList
  );
  const loading = useAppSelector((state) => state.dashboard.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? TotalAirlineVoucherList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TotalAirlineVoucherList && TotalAirlineVoucherList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(TotalAirlineVoucherList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : TotalAirlineVoucherList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={TotalAirlineVoucherList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="AirlineVoucherNo" title="Airline Voucher No" />
          <Column field="TourBookingNo" title="Tour Booking No" />
          <Column field="CompanyName" title="Comapany Name" />
          <Column field="TicketPurchasedDate" title="Ticket Issue Date" />
          <Column field="Journey" title="Journey" />
          <Column field="NoOfPax" title="No Of Pax" />
          <Column field="TotalPrice" title="Total Price" />
          <Column field="BookBy" title="Book By" />
          {/* <Column field="Branch" title="Branch" /> */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default TotalAirlineVoucher;
