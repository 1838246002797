import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { SuccessToast, ErrorToast } from "../../../components/toast/Toasts";
import { IGroupRoute } from "../groupRouteModel";
import moment from "moment";

export const getAllGroupRoutes = createAsyncThunk(
  "GroupRoute/FindAllGroupRoute",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/FindAllGroupRoute`,
        {
          RouteName: "",
        }
      );
      return response.data?.Data as IGroupRoute[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Group Routes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveGroupRoute = createAsyncThunk(
  "GroupRoute/FindAllActiveGroupRoute",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/GroupRoute/FindAllActiveGroupRoute`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Group Routes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createGroupRoute = createAsyncThunk(
  "GroupRoute/InsertGroupRoute",
  async (GroupRouteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/InsertGroupRoute`,
        GroupRouteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating group route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateGroupRoute = createAsyncThunk(
  "GroupRoute/UpdateGroupRoute",
  async (GroupRouteData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/UpdateGroupRoute`,
        GroupRouteData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating group route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteGroupRoute = createAsyncThunk(
  "GroupRoute/DeleteGroupRoute",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/DeleteGroupRoute`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting group route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGroupRouteByID = createAsyncThunk(
  "GroupRoute/FindByIDGroupRoute",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/FindByIDGroupRoute`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorID: result.SectorID,
        SectorName: result.SectorName,
        RouteName: result.RouteName,
        StatDate: result?.StatDate
          ? moment(result?.StatDate, "YYYY-MM-DD").toDate()
          : "",
        ManagerDetails: result.ManagerDetails,
        HotelID:
          result?.HotelDetailsInfo?.length > 0
            ? result?.HotelDetailsInfo?.map((hotel: any) => hotel.HotelID).join(
                ","
              )
            : "",
        Remarks: result.Remarks,
        IsActive: result.IsActive ? 1 : 2,
        GroupRouteInfo: result.GroupRouteInfo
          ? result.GroupRouteInfo.map((GRInfo: any) => {
              return {
                GroupRouteID: GRInfo.GroupRouteID ? +GRInfo.GroupRouteID : null,
                StatDate: GRInfo?.StatDate
                  ? moment(GRInfo?.StatDate, "YYYY-MM-DD").toDate()
                  : "",
                StartDate: GRInfo?.StatDate
                  ? moment(GRInfo?.StatDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : "",
                DayNo: GRInfo.DayNo ? +GRInfo.DayNo : null,
                RouteID: GRInfo.RouteID ? +GRInfo.RouteID : null,
                RouteName: GRInfo.RouteName ? GRInfo.RouteName : "",
                CityID: GRInfo.CityID ? +GRInfo.CityID : null,
                CityName: GRInfo.CityName ? GRInfo.CityName : "",
                Description: GRInfo.Description ? GRInfo.Description : "",
              };
            })
          : [],
        HotelDetailsInfo: result.HotelDetailsInfo
          ? result.HotelDetailsInfo.map((hotelInfo: any) => {
              return {
                HotelID: hotelInfo?.HotelID,
                HotelName: hotelInfo?.HotelName,
                HotelCode: hotelInfo?.HotelCode,
                HotelDisplayName: hotelInfo?.HotelDisplayName,
                HotelAddress: hotelInfo?.HotelAddress,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching group route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveGroupRoute = createAsyncThunk(
  "GroupRoute/ActiveInActiveGroupRoute",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/ActiveInActiveGroupRoute`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive group route:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGroupRouteBySectorID = createAsyncThunk(
  "GroupRoute/GroupRouteBySectorID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/GroupRouteBySectorID`,
        {
          SectorID: ID,
        }
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Group Route by Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
