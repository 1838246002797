import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAirlineVoucherState } from "./airlineVoucherModel";
import {
  createAirlineVoucher,
  deleteAirlineVoucher,
  getAirlineSegmentView,
  getAirlineTicketPDFByID,
  getAirlineVoucherByID,
  getAllAirlineVouchers,
  getTourBookingPaxList,
  updateAirlineVoucher,
} from "./services/airlineVoucher.services";

const initialState: IAirlineVoucherState = {
  loading: false,
  error: "",
  deleteIndex: -1,
  departureDeleteIndex: -1,
  returnDeleteIndex: -1,
  AirlineVoucherID: null,
  AirlineVoucherList: [],
  AirlineVoucherDetails: {
    TourBookingNo: "",
    AirlineBlockID: null,
    CompanyID: null,
    TicketPurchasedDate: "",
    Journey: "",
    NoOfPax: null,
    TotalPrice: null,
    OTP: "",
    BookBy: null,
    Remarks: "",
    OldAirlineVoucherNo: "",
    AirlineBlockDateID: null,
    airlineVoucherSegments: [],
    airlineVoucherTravellers: [],
    IsActive: true,
    IsOnlyAirlineTicket: false,
  },
};

const airlineVoucherSlice = createSlice({
  name: "airlineVoucher",
  initialState,
  reducers: {
    clearAirlineVoucherDetails: (state) => {
      state.AirlineVoucherDetails = initialState.AirlineVoucherDetails;
    },
    setAirlineVoucherID: (state, action) => {
      state.AirlineVoucherID = action.payload;
    },
    clearAirlineVoucherID: (state) => {
      state.AirlineVoucherID = initialState.AirlineVoucherID;
    },
    setSegmentDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
    setDepartureSegmentDeleteIndex: (state, action) => {
      state.departureDeleteIndex = action.payload;
    },
    setReturnSegmentDeleteIndex: (state, action) => {
      state.returnDeleteIndex = action.payload;
    },
    setIsOnlyAirlineTicket: (state, action) => {
      state.AirlineVoucherDetails.IsOnlyAirlineTicket = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAirlineVouchers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllAirlineVouchers.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.AirlineVoucherList = action.payload || [];
        state.error = "";
      }
    );
    builder.addCase(getAllAirlineVouchers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineVoucherList = [];
    });
    builder.addCase(createAirlineVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createAirlineVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAirlineVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAirlineVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateAirlineVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAirlineVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAirlineVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAirlineVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAirlineVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAirlineVoucherByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAirlineVoucherByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.AirlineVoucherDetails = action.payload;
    });
    builder.addCase(getAirlineVoucherByID.rejected, (state, action) => {
      state.loading = false;
      state.AirlineVoucherDetails = initialState.AirlineVoucherDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourBookingPaxList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourBookingPaxList.fulfilled, (state) => {
      state.loading = false;
      // state.AirlineVoucherDetails = {
      //   ...state.AirlineVoucherDetails,
      //   airlineVoucherTravellers: action.payload || [],
      // };
    });
    builder.addCase(getTourBookingPaxList.rejected, (state, action) => {
      state.loading = false;
      // state.AirlineVoucherDetails = initialState.AirlineVoucherDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAirlineSegmentView.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAirlineSegmentView.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getAirlineSegmentView.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAirlineTicketPDFByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAirlineTicketPDFByID.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getAirlineTicketPDFByID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const {
  clearAirlineVoucherDetails,
  setAirlineVoucherID,
  clearAirlineVoucherID,
  setSegmentDeleteIndex,
  setDepartureSegmentDeleteIndex,
  setReturnSegmentDeleteIndex,
  setIsOnlyAirlineTicket,
} = airlineVoucherSlice.actions;
export default airlineVoucherSlice.reducer;
