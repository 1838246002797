import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IPaymentReceipt,
  IPaymentReceiptInitialState,
} from "./paymentReceiptModel";
import {
  // ActiveInactivePaymentReceipt,
  createPaymentReceipt,
  deletePaymentReceipt,
  getAllActivePaymentReceipts,
  getAllPaymentReceipts,
  getPaymentReceiptByID,
  getPaymentReceiptSummaryByAirlineVoucherNo,
  getPaymentReceiptSummaryByBookingNo,
  updatePaymentReceipt,
} from "./services/paymentReceipt.services";
import moment from "moment";

const initialState: IPaymentReceiptInitialState = {
  loading: false,
  error: "",
  PaymentReceiptID: null,
  PaymentReceiptList: [],
  PaymentReceiptCostDetail: {
    PNRNo: "",
    Name: "",
    TourAmount: null,
    TourCancellationAmount: null,
    AirTicketAmount: null,
    TotalTourBookingAmount: null,
    TotalPaidAmount: null,
    RefundAmount: null,
    RemainingAmount: null,
    Company: "",
    CompanyID: null,
    PaymentFor: "",
    PaymentHistory: [],
  },
  PaymentReceiptDetail: {
    ReceiptNo: "",
    PaymentFor: "",
    BookingNo: "",
    AirlineVoucherNo: "",
    IsOnlyAirlineTicket:false,
    // OldReceiptNo: "",
    Name: "",
    CompanyID: null,
    PaymentDate: moment().toDate(),
    Amount: null,
    Commission: null,
    Kasar: "",
    PaymentType: "",
    BankName: "",
    BranchName: "",
    IsRefund: 2,
    ReceivedByID: null,
    Remarks: "",
  },
};

const paymentReceiptSlice = createSlice({
  name: "paymentReceipt",
  initialState,
  reducers: {
    clearPaymentReceiptDetails: (state) => {
      state.PaymentReceiptDetail = initialState.PaymentReceiptDetail;
    },
    clearPaymentReceiptCostDetail: (state) => {
      state.PaymentReceiptCostDetail = initialState.PaymentReceiptCostDetail;
    },
    setPaymentReceiptID: (state, action) => {
      state.PaymentReceiptID = action.payload;
    },
    clearPaymentReceiptID: (state) => {
      state.PaymentReceiptID = initialState.PaymentReceiptID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPaymentReceipts.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPaymentReceipts.fulfilled,
      (state, action: PayloadAction<IPaymentReceipt[]>) => {
        state.loading = false;
        state.PaymentReceiptList = action.payload || [];
      }
    );
    builder.addCase(getAllPaymentReceipts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaymentReceiptList = [];
    });
    builder.addCase(createPaymentReceipt.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createPaymentReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPaymentReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updatePaymentReceipt.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updatePaymentReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePaymentReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deletePaymentReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePaymentReceipt.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePaymentReceipt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPaymentReceiptByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getPaymentReceiptByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.PaymentReceiptDetail = action.payload;
    });
    builder.addCase(getPaymentReceiptByID.rejected, (state, action) => {
      state.loading = false;
      state.PaymentReceiptDetail = initialState.PaymentReceiptDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPaymentReceiptSummaryByBookingNo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPaymentReceiptSummaryByBookingNo.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.PaymentReceiptCostDetail = action.payload;
      }
    );
    builder.addCase(
      getPaymentReceiptSummaryByBookingNo.rejected,
      (state, action) => {
        state.loading = false;
        state.PaymentReceiptCostDetail = initialState.PaymentReceiptCostDetail;
        state.error = action.error.message || "Something went wrong";
      }
    );

    builder.addCase(
      getPaymentReceiptSummaryByAirlineVoucherNo.pending,
      (state) => {
        state.loading = true;
        state.error = "";
      }
    );
    builder.addCase(
      getPaymentReceiptSummaryByAirlineVoucherNo.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.PaymentReceiptCostDetail = action.payload;
      }
    );
    builder.addCase(
      getPaymentReceiptSummaryByAirlineVoucherNo.rejected,
      (state, action) => {
        state.loading = false;
        state.PaymentReceiptCostDetail = initialState.PaymentReceiptCostDetail;
        state.error = action.error.message || "Something went wrong";
      }
    );

    builder.addCase(getAllActivePaymentReceipts.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActivePaymentReceipts.fulfilled,
      (state, action: PayloadAction<IPaymentReceipt[]>) => {
        state.loading = false;
        state.PaymentReceiptList = action.payload || [];
      }
    );
    builder.addCase(getAllActivePaymentReceipts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaymentReceiptList = [];
    });
    // builder.addCase(ActiveInactivePaymentReceipt.pending, (state) => {
    //   state.loading = true;
    //   state.error = "";
    // });
    // builder.addCase(ActiveInactivePaymentReceipt.fulfilled, (state) => {
    //   state.loading = false;
    // });
    // builder.addCase(ActiveInactivePaymentReceipt.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message || "Something went wrong";
    // });
  },
});

export const {
  clearPaymentReceiptDetails,
  clearPaymentReceiptCostDetail,
  clearPaymentReceiptID,
  setPaymentReceiptID,
} = paymentReceiptSlice.actions;
export default paymentReceiptSlice.reducer;
