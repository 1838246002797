import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getGroupRouteByID } from "./services/groupRoute.services";
import Logo from "../../assets/Images/logo.png";
import { LoadingPanel } from "../../components/layout/Loading";

const GroupRoutePrinttwo: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const GroupRouteID = params?.GroupRouteID;
  const GroupRouteDetail = useAppSelector(
    (state) => state.groupRoute.GroupRouteDetail
  );

  const loading = useAppSelector((state) => state.groupRoute.loading);

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
    @media print {
    @page {
      border: 2px inset #ccc;
    }
  }`;
    document.head.appendChild(style);
  };
  useEffect(() => {
    setPrintStyles();
    if (GroupRouteID) {
      dispatch(getGroupRouteByID(+GroupRouteID));
    }
  }, [GroupRouteID]);
  
  // const [rows, setRows] = useState<any>(GroupRouteDetail?.GroupRouteInfo);
  const gridRef = useRef<any>(null);
  // console.log("GroupRouteDetail", GroupRouteDetail);
  // useEffect(() => {
  //   setRows(GroupRouteDetail?.GroupRouteInfo);
  // }, [GroupRouteDetail?.GroupRouteInfo]);
  // const [draggedRow, setDraggedRow] = useState<number | null>(null);
  // const handleDragStart = (index: number) => {
  //   setDraggedRow(index);
  // };
  // const handleDragOver = (index: number, event: React.DragEvent) => {
  //   event.preventDefault(); // Prevent default to allow drop
  //   if (draggedRow !== null && draggedRow !== index) {
  //     const updatedRows = [...rows];
  //     const [draggedItem] = updatedRows.splice(draggedRow, 1); // Remove dragged item
  //     updatedRows.splice(index, 0, draggedItem); // Insert dragged item at new position
  //     setRows(updatedRows);
  //     setDraggedRow(index); // Update dragged row index
  //   }
  // };
  // const handleDrop = () => {
  //   setDraggedRow(null); // Reset dragged row
  // };

  return (
    <>
      {loading ? (
        <LoadingPanel gridRef={gridRef} />
      ) : (
        <>
          <div
            className="container-fluid"
            style={{
              fontSize: 12,
              fontFamily: "'Roboto' serif",
              color: "#000",
            }}
          >
            <table>
              <thead>
                <tr>
                  <td>
                    <header className="row print-header">
                      <div className="col-12 text-center">
                        <img
                          src={Logo}
                          alt=""
                          style={{ height: "80px", objectFit: "contain" }}
                        />
                      </div>
                      <div className="col-12 text-center">
                        <p className="p-0 mb-2" style={{ fontWeight: "bold" }}>
                          <span style={{ color: "red", fontSize: "14px" }}>
                            11,
                          </span>{" "}
                          Podium Floor, City Center, Chimanlal Girdharlal Rd,
                          Nr. Swastik Cross Road, Swastik Society, Navrangpura,
                          Ahmedabad - 380009
                        </p>
                        <div className="d-flex" style={{ gap: 100 }}></div>
                      </div>
                      <div className="col-3 text-end"></div>
                    </header>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-3">
                    {/*ITINERARY DETAILS */}
                    <div className="row   pb-2">
                      <div className="col-12 p-0">
                        <h5
                          className="text-center  my-4"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            textUnderlineOffset: "4px",
                            textDecorationColor: "black",
                            textDecorationThickness: "1px",
                          }}
                        >
                          TOUR ITINERARY DETAILS
                        </h5>
                        <table
                          cellPadding={1}
                          className="itinerarytabletwo w-100 "
                        >
                          <tr
                            className="text-white text-center"
                            style={{ textTransform: "uppercase" }}
                          >
                            <th>Day</th>
                            <th>Tour Itinerary Program</th>
                            <th>Night Staying</th>
                          </tr>
                          {GroupRouteDetail?.GroupRouteInfo &&
                            GroupRouteDetail?.GroupRouteInfo?.length > 0 &&
                            GroupRouteDetail?.GroupRouteInfo?.map(
                              (item: any, index: any) => (
                                <tr
                                  key={index}
                                  // draggable
                                  // onDragStart={() => handleDragStart(index)}
                                  // onDragOver={(event) =>
                                  //   handleDragOver(index, event)
                                  // }
                                  // onDrop={handleDrop}
                                  // style={{ cursor: "grab" }}
                                >
                                  <td
                                    className="text-center"
                                    style={{ width: "10%" }}
                                  >
                                    <span
                                      className="fw-600"
                                      style={{ color: "red" }}
                                    >
                                      DAY {item?.DayNo}
                                    </span>
                                    <br />
                                    <b>{item?.StartDate}</b>
                                  </td>
                                  <td style={{ width: "70%" }}>
                                    <div style={{ textAlign: "left" }}>
                                      <span
                                        className="d-block fw-600"
                                        style={{
                                          color: "red",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {item?.RouteName}
                                      </span>
                                      <strong> {item?.Description}</strong>
                                    </div>
                                  </td>
                                  <td
                                    className="  text-center"
                                    style={{ width: "20%" }}
                                  >
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.CityName}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                        </table>
                      </div>
                    </div>
                    {/* HOTEL DETAILS */}
                    <div style={{ margin: "mt-5" }}>
                      <h5
                        className="my-4 mb-3 text-center"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                          textDecorationColor: "black",
                          textDecorationThickness: "1px",
                        }}
                      >
                        HOTEL DETAILS
                      </h5>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gridGap: "10px",
                        }}
                      >
                        {GroupRouteDetail?.HotelDetailsInfo?.map(
                          (item: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "2px dotted grey",
                                borderRadius: "10px",
                                padding: "10px",
                              }}
                              className="mt-1"
                            >
                              <div
                                style={{
                                  maxWidth: "400px",
                                  textAlign: "center",
                                }}
                              >
                                <h6 className="m-0 mb-2 pt-2">
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    {item?.HotelName}
                                  </span>
                                  {item?.HotelCode ? (
                                    <b>&nbsp;( {item?.HotelCode} )</b>
                                  ) : (
                                    ""
                                  )}
                                </h6>
                                <h6
                                  className="m-0"
                                  style={{ fontWeight: "300" }}
                                >
                                  {item?.HotelAddress}
                                </h6>
                                {item?.HotelPhone && (
                                  <h6
                                    className="m-0 "
                                    style={{ fontWeight: "300" }}
                                  >
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Ph :
                                    </span>{" "}
                                    <b>{item?.HotelPhone}</b>
                                  </h6>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {/* REMARK */}
                    <div className="row mt-5 text-center">
                      <h6>
                        <h5
                          className="m-0 mb-3 text-center"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            textUnderlineOffset: "4px",
                            textDecorationColor: "black",
                            textDecorationThickness: "1px",
                          }}
                        >
                          REMARKS
                        </h5>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: GroupRouteDetail?.Remarks,
                          }}
                        />
                      </h6>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default GroupRoutePrinttwo;
