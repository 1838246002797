import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";
// import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getCompanyWiseBookingReport = createAsyncThunk(
  "TourBookingReport/CompanyWiseBookingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/CompanyWiseBookingReport`,
        formData
      );
      return response.data?.Data;
      // return result.map((company: any, index: number) => {
      //   return {
      //     UniqueNo: index + 1,
      //     TourDateCode: company.TourDateCode,
      //     TourBookingNo: company.TourBookingNo,
      //     TourDate: company.TourDate,
      //     Name: company.Name,
      //     MobileNo: company.MobileNo,
      //     TotalNoOfPax: company.TotalNoOfPax,
      //     PANCardNo: company.PANCardNo,
      //     CompanyGSTNo: company.CompanyGSTNo,
      //     TourCost: company.TourCost,
      //     TicketAmount: company.TicketAmount,
      //     PaidAmount: company.PaidAmount,
      //     RemainingAmount: company.RemainingAmount,
      //     BookByName: company.BookByName,
      //     Company: company.Company,
      //     Branch: company.Branch,
      //   };
      // });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Company Wise Tour Booking Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getCompanyWisePendingPaymentReport = createAsyncThunk(
  "TourBookingReport/CompanyWisePendingPaymentReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/CompanyWisePendingPaymentReport`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error Fetching Company Wise Pending Payment Report:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getVehicleAllotmentReport = createAsyncThunk(
  "TourBookingReport/VehicleAllotmentReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/VehicleAllotmentReport`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vehicle Allotment Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserWiseTourBookingReport = createAsyncThunk(
  "TourBookingReport/UserWiseTourBookingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/UserWiseTourBookingReport`,
        formData
      );
      return response.data?.Data;
      // return result.map((user: any, index: number) => {
      //   return {
      //     UniqueNo: index + 1,
      //     ID: user.ID,
      //     TourBookingNo: user.TourBookingNo,
      //     TourName: user.TourName,
      //     CustomerName: user.CustomerName,
      //     MobileNo: user.MobileNo,
      //     TourStartDate: user.TourStartDate,
      //     NoOfNights: user.NoOfNights,
      //     BookBy: user.BookBy,
      //     TotalPax: user.TotalPax,
      //     TotalAdults: user.TotalAdults,
      //     TotalChilds: user.TotalChilds,
      //     TourBookingStatus: user.TourBookingStatus,
      //     BookingDate: user.BookingDate,
      //     Branch: user.Branch,
      //   };
      // });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching User Wise Tour Booking Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHotelBlockRoomStatus = createAsyncThunk(
  "TourBookingReport/HotelBlockRoomStatus",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/HotelBlockRoomStatus`,
        formData
      );
      return response.data?.Data;
      // return result.map((hotel: any, index: number) => {
      //   return {
      //     UniqueNo: index + 1,
      //     TourName: hotel.TourName,
      //     TourDateCode: hotel.TourDateCode,
      //     TourDate: hotel.TourDate,
      //     NoOfRoom: hotel.NoOfRoom,
      //     OccupiedRoom: hotel.OccupiedRoom,
      //     BalanceRoom: hotel.BalanceRoom,
      //     NoOfPax: hotel.NoOfPax,
      //     Remarks: hotel.Remarks,
      //   };
      // });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel Block Room Status Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCustomerListReport = createAsyncThunk(
  "TourBookingReport/CustomerListReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/CustomerListReport`,
        formData
      );
      return response.data?.Data;
      // return result.map((customer: any, index: number) => {
      //   return {
      //     UniqueNo: index + 1,
      //     ID: customer.ID,
      //     TourBookingNo: customer.TourBookingNo,
      //     TourName: customer.TourName,
      //     CustomerName: customer.CustomerName,
      //     MobileNo: customer.MobileNo,
      //     EmailID: customer.EmailID,
      //     TotalNoOfRooms: customer.TotalNoOfRooms,
      //     TotalCNB: customer.TotalCNB,
      //     TotalCWB: customer.TotalCWB,
      //     TotalInfants: customer.TotalInfants,
      //     ExtraBed: customer.ExtraBed,
      //     PickUpPlace: customer.PickUpPlace,
      //     DropPlace: customer.DropPlace,
      //     Foodoption: customer.Foodoption,
      //     TourStartDate: customer.TourStartDate,
      //     NoOfNights: customer.NoOfNights,
      //     BookBy: customer.BookBy,
      //   };
      // });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Customer Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingReport = createAsyncThunk(
  "TourBookingReport/TourBookingForm",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/TourBookingForm`,
        {
          TourBookingNo: ID,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPaymentReceiptCustomerView = createAsyncThunk(
  "PaymentReceipt/PaymentReceiptCustomerView",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/PaymentReceiptCustomerView`,
        {
          ReceiptNo: ID,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching customer payment receipt report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourReport = createAsyncThunk(
  "TourBookingReport/TourReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/TourReport`,
        formData
      );
      return response.data?.Data;
      // return result.map((tour: any, index: number) => {
      //   return {
      //     UniqueNo: index + 1,
      //     ID: tour.ID,
      //     TourBookingNo: tour.TourBookingNo,
      //     TourName: tour.TourName,
      //     CustomerName: tour.CustomerName,
      //     MobileNo: tour.MobileNo,
      //     TotalNoOfRooms: tour.TotalNoOfRooms,
      //     TotalCNB: tour.TotalCNB,
      //     TotalCWB: tour.TotalCWB,
      //     TotalInfants: tour.TotalInfants,
      //     ExtraBed: tour.ExtraBed,
      //     PickUpPlace: tour.PickUpPlace,
      //     DropPlace: tour.DropPlace,
      //     Foodoption: tour.Foodoption,
      //   };
      // });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingInvoice = createAsyncThunk(
  "TourBookingReport/TourBookingInvoice",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/TourBookingInvoice`,
        {
          TourBookingNo: ID,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineBlockFlights = createAsyncThunk(
  "AirlineBlock/AirlineBlockFlightList",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/AirlineBlockFlightList`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Airline Block Flight:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineBlockBookingExcelSheetData = createAsyncThunk(
  "TourBookingReport/AirlineBlockBookingExcelSheetData",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/AirlineBlockBookingExcelSheetData`,
        {
          AirlineBlockDateID: ID,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLedgerReport = createAsyncThunk(
  "PaymentReceipt/ManageLedgerAccount",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/ManageLedgerAccount`,
        payload
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching ledger report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getKasarReport = createAsyncThunk(
  "TourBookingReport/KasarReportView",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/KasarReportView`,
        payload
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching kasar report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

