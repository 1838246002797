import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import { getAllActiveSectors } from "../sector/services/sector.services";
import {
  createGroupRoute,
  getGroupRouteByID,
  updateGroupRoute,
} from "./services/groupRoute.services";
import { clearGroupRouteDetails } from "./groupRouteSlice";
import { ISector } from "../sector/sectorModel";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import GroupRouteInfoArray from "./GroupRouteInfoArray";
import { STATUSARRAY } from "../../_contstants/common";
import { getCityBySectorID } from "../city/services/city.services";
import moment from "moment";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getAllActiveHotels } from "../hotel/services/hotel.services";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isCityRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  const GroupRouteInfo = formRenderProps.valueGetter("GroupRouteInfo");
  useEffect(() => {
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }
    if (!isCityRef.current) {
      GroupRouteInfo?.map((item: any, index: number) => {
        formRenderProps.onChange(`GroupRouteInfo.${index}.CityID`, {
          value: null,
        });
      });
    } else {
      isCityRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const CreateGroupRoute: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const GroupRouteID = location.state?.GroupRouteID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const loading = useAppSelector(
    (state: RootState) => state.groupRoute.loading
  );
  const GroupRouteDetail = useAppSelector(
    (state: RootState) => state.groupRoute.GroupRouteDetail
  );
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const HotelList = useAppSelector((state) => state.hotel.HotelList);

  useEffect(() => {
    dispatch(getAllActiveHotels());
    dispatch(getAllActiveSectors());
  }, []);

  useEffect(() => {
    if (GroupRouteID) {
      dispatch(getGroupRouteByID(GroupRouteID));
    }
    return () => {
      dispatch(clearGroupRouteDetails());
    };
  }, [GroupRouteID]);

  const handleSubmit = async (values: any) => {
    if (GroupRouteID) {
      const editPayload = {
        ID: values.ID,
        SectorID: values.SectorID ? values.SectorID : null,
        RouteName: values.RouteName ? values.RouteName : "",
        StatDate: values?.StatDate
          ? moment(values?.StatDate).format("YYYY-MM-DD")
          : "",
        HotelID: Array.isArray(values.HotelID)
          ? values.HotelID.join(",")
          : values.HotelID,
        ManagerDetails: values.ManagerDetails ? values.ManagerDetails : "",
        Remarks: values.Remarks ? values.Remarks : null,
        IsActive: values.IsActive === 1,
        GroupRouteInfo: values.GroupRouteInfo
          ? values.GroupRouteInfo.map((GRInfo: any, index: any) => {
              return {
                DayNo: index + 1,
                RouteID: GRInfo.RouteID ? +GRInfo.RouteID : null,
                CityID: GRInfo.CityID ? +GRInfo.CityID : null,
                Description: GRInfo.Description ? GRInfo.Description : "",
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateGroupRoute(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearGroupRouteDetails());
          navigate("/grouproute");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        ID: 0,
        SectorID: values.SectorID,
        RouteName: values.RouteName,
        StatDate: values?.StatDate
          ? moment(values?.StatDate).format("YYYY-MM-DD")
          : "",
        HotelID: Array.isArray(values.HotelID)
          ? values.HotelID.join(",")
          : values.HotelID,
        ManagerDetails: values.ManagerDetails,
        Remarks: values.Remarks,
        IsActive: values.IsActive === 1,
        GroupRouteInfo: values.GroupRouteInfo
          ? values.GroupRouteInfo.map((GRInfo: any, index: any) => {
              return {
                DayNo: index + 1,
                RouteID: GRInfo.RouteID ? +GRInfo.RouteID : null,
                CityID: GRInfo.CityID ? +GRInfo.CityID : null,
                Description: GRInfo.Description ? GRInfo.Description : "",
              };
            })
          : [],
      };
      try {
        const response = await dispatch(createGroupRoute(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(clearGroupRouteDetails());
          navigate("/grouproute");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const filterHotels = (SectorID: number) => {
    if (SectorID) {
      const result = HotelList.filter((hotel: any) => {
        return hotel.SectorID === SectorID;
      });
      return result.map((hotel) => {
        return {
          value: hotel.ID,
          label: hotel.HotelName,
        };
      });
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={GroupRouteDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {GroupRouteID ? "Update Group Route" : "Create Group Route"}
                  </Typography.h4>
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    id={"SectorID"}
                    name={"SectorID"}
                    label={"Sector"}
                    placeholder="Select Sector"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorList?.map((sector: ISector) => {
                      return {
                        value: sector?.ID,
                        label: sector?.SectorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="RouteName"
                    label="Group Route Title"
                    placeholder="Group Route Title"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="ManagerDetails"
                    label="Manager Details"
                    placeholder="Manager Details"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name="Remarks"
                    label="Remarks"
                    placeholder="Remarks..."
                    style={{ height: "200px" }}
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="HotelID"
                    label="Hotel"
                    placeholder="Select Hotel"
                    component={FormMultiSelectionFiled}
                    options={
                      HotelList &&
                      filterHotels(formRenderProps.valueGetter("SectorID"))
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="StatDate"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`IsActive`}
                    label="Status"
                    placeholder="Select Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: "10px" }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={GroupRouteInfoArray}
                    name="GroupRouteInfo"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 10,
                  }}
                >
                  <RippleButton
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/grouproute")}
                  >
                    Cancel
                  </RippleButton>
                  <ButtonWithLoading
                    label={GroupRouteID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <SectorChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateGroupRoute;
