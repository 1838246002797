import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { GENDER, NAMEPREFIX } from "../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { INationality } from "../nationality/nationalityModel";
import { useEffect } from "react";
import { getAllActiveNationalities } from "../nationality/services/nationality.services";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";

const AirlineVoucherTravellersArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );
  useEffect(() => {
    dispatch(getAllActiveNationalities());
  }, []);

  const pushElementInarray = () => {
    fieldArrayRenderProps.onPush({
      value: {
        FirstName: "",
        LastName: "",
        Gender: "",
        PaxType: "",
        NationalityID: null,
        PassportNo: "",
        IssueDate: "",
        ExpiryDate: "",
        Initial: "",

        // TicketFrom: "",
        // AirlineBlockID: null,
        // Price: null,
        // FileName: "",
      },
    });
  };

  return (
    <>
      <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem style={{ marginBottom: 10 }}>
          <Typography.h4>Traveller Details</Typography.h4>
        </GridLayoutItem>
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (airlineVoucherTravellers: any, index: number) => (
              <GridLayoutItem key={index}>
                <Card
                  className="fieldarray-card"
                  style={{
                    padding: "20px",
                  }}
                >
                  <GridLayout
                    style={{ marginRight: 50 }}
                    gap={{ rows: 10, cols: 10 }}
                    cols={[
                      { width: "8%" },
                      { width: "18.4%" },
                      { width: "18.4%" },
                      { width: "18.4%" },
                      { width: "18.4%" },
                      { width: "18.4%" },
                    ]}
                  >
                    <GridLayoutItem
                      rowSpan={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Field
                        name={`airlineVoucherTravellers.${index}.IsSelected`}
                        size="large"
                        checkboxStyle={{ borderColor: "grey" }}
                        component={FormCheckbox}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.Initial`}
                        label="Initial"
                        component={FormSelectionField}
                        options={NAMEPREFIX?.map((initial: any) => {
                          return {
                            value: initial?.value,
                            label: initial?.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.FirstName`}
                        label="First Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.LastName`}
                        label="Last Name"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.Gender`}
                        label="Gender"
                        component={FormSelectionField}
                        options={GENDER?.map((gender: any) => {
                          return {
                            value: gender?.value,
                            label: gender?.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.PaxType`}
                        label="PaxType"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.NationalityID`}
                        label="Nationality"
                        component={FormSelectionField}
                        options={NationalityList?.map(
                          (nationality: INationality) => {
                            return {
                              value: nationality?.ID,
                              label: nationality?.Nationality,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.PassportNo`}
                        label="Passport No"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.IssueDate`}
                        label="Issue Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={`airlineVoucherTravellers.${index}.ExpiryDate`}
                        label="Expiry Date"
                        format="dd/MM/yyyy"
                        minDate={moment(
                          fieldArrayRenderProps.formRenderProps.valueGetter(
                            `airlineVoucherTravellers.${index}.IssueDate`
                          )
                        ).toDate()}
                        component={FormDatePicker}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </Card>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
        style={{marginTop:10}}
        >
          <GridLayout style={{ marginRight: 50 }} gap={{ rows: 10, cols: 10 }}>
            <GridLayoutItem className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Traveller
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default AirlineVoucherTravellersArray;
