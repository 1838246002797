import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { SuccessToast, ErrorToast } from "../../../components/toast/Toasts";
import { IHotel, IHotelDetails } from "../hotelModel";

export const getAllHotels = createAsyncThunk(
  "Hotels/FindAllHotels",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Hotels/FindAllHotels`,
        {
          HotelName: "",
        }
      );
      return response.data?.Data as IHotel[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotels:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveHotels = createAsyncThunk(
  "Hotels/FindAllActiveHotels",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Hotels/FindAllActiveHotels`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching hotels:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createHotel = createAsyncThunk(
  "Hotels/InsertHotels",
  async (HotelData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Hotels/InsertHotels`,
        HotelData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Hotel:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateHotel = createAsyncThunk(
  "Hotels/UpdateHotels",
  async (HotelData: IHotelDetails) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Hotels/UpdateHotels`,
        HotelData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Hotel:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteHotel = createAsyncThunk(
  "Hotels/DeleteHotels",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Hotels/DeleteHotels`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Hotel:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHotelByID = createAsyncThunk(
  "Hotels/FindByIDHotels",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Hotels/FindByIDHotels`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        HotelName: result.HotelName,
        HotelCode: result.HotelCode,
        HotelURL: result.HotelURL,
        HotelDisplayName: result.HotelDisplayName,
        HotelAddress: result.HotelAddress,
        LandMark: result.LandMark,
        CountryID: result.CountryID,
        Country: result.Country,
        StateID: result.StateID,
        City: result.City,
        State: result.State,
        CityID: result.CityID,
        SectorID: result.SectorID,
        // LatitudeOfHotel: result.LatitudeOfHotel,
        // LongitudeOfHotel: result.LongitudeOfHotel,
        MapURL: result.MapURL,
        StarRating: result.StarRating,
        PropertyType: result.PropertyType,
        Inclusions: result.Inclusions,
        Exclusions: result.Exclusions,
        HotelInformation: result.HotelInformation,
        VendorID: result.VendorID,
        IsActive: result.IsActive ? 1 : 2,
        HotelContacts: result.HotelContacts
          ? result.HotelContacts.map((contact: any) => {
              return {
                HotelID: contact.HotelID ? contact.HotelID : null,
                PersonName: contact.PersonName ? contact.PersonName : "",
                MobileNo: contact.MobileNo ? contact.MobileNo : "",
                EmailID: contact.EmailID ? contact.EmailID : "",
                AlternateMobileNo: contact.AlternateMobileNo
                  ? contact.AlternateMobileNo
                  : "",
                AlternateEmailID: contact.AlternateEmailID
                  ? contact.AlternateEmailID
                  : "",
                IsOwner: contact.IsOwner ? 1 : 2,
              };
            })
          : [],
      } as IHotelDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Hotel:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveHotel = createAsyncThunk(
  "Hotels/ActiveInActiveHotels",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Hotels/ActiveInActiveHotels`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Hotel:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
