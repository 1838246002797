import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllActiveTours } from "../../tour/services/tour.services";
import { clearTourReportList } from "../reportSlice";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { clearTourDateList } from "../../hotelblock/hotelBlockSlice";
import { getKasarReport } from "../services/reports.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const DummyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  useEffect(() => {
    formRenderProps.onChange("userID", {
      value: null,
    });
  }, []);

  return null;
};

const KasarReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const KasarReport = useAppSelector((state) => state.reports.KasarReport);

  useEffect(() => {
    dispatch(getAllActiveTours());

    return () => {
      dispatch(clearTourReportList());
      dispatch(clearTourDateList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      FromDate: values?.FromDate
        ? moment(values?.FromDate).format("YYYY-MM-DD")
        : null,
      ToDate: values?.ToDate
        ? moment(values?.ToDate).format("YYYY-MM-DD")
        : null,
    };

    dispatch(getKasarReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ cols: 10, rows: 0 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Kasar Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="FromDate"
                      label="From Date"
                      format="dd/MM/yyyy"
                      disabled={
                        formRenderProps.valueGetter("TourDate") ? true : false
                      }
                      wrapperStyle={{ width: "100%" }}
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="ToDate"
                      label="To Date"
                      format="dd/MM/yyyy"
                      disabled={
                        formRenderProps.valueGetter("TourDate") ? true : false
                      }
                      wrapperStyle={{ width: "100%" }}
                      component={FormDatePicker}
                      validator={
                        formRenderProps.valueGetter("FromDate") &&
                        requiredValidator
                      }
                    />
                  </GridLayoutItem>

                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      position: "relative",
                      minHeight: 90,
                    }}
                  >
                    <div style={{ position: "absolute", top: 42, right: 0 }}>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                  <DummyChangeWatcher formRenderProps={formRenderProps} />
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>

      <GridLayoutItem style={{ marginTop: "20px" }}>
        <ShadowCard style={{ width: "500px", margin: "auto" }}>
          <div
            style={{
              textAlign: "center",
              padding: "50px",
              border: "1px dotted black",
              borderRadius: "5px",
            }}
          >
            <h4>KASAR</h4>
            <h2>
              {KasarReport.Kasar ? (
                <span style={{ color: "green" }}>
                  {Number(KasarReport?.Kasar)?.toFixed(2)}
                </span>
              ) : (
                <span style={{ color: "red" }}>0.00</span>
              )}
            </h2>
          </div>
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default KasarReportForm;
