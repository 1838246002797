import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import { sumBy } from "lodash";
import { getTourBookingReport } from "../services/reports.services";

const TourBookingReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const TourBookingNo = params?.TourBookingNo;
  const TourBookingReportDetails = useAppSelector(
    (state) => state.reports.TourBookingReportDetails
  );

  useEffect(() => {
    if (TourBookingNo) {
      dispatch(getTourBookingReport(TourBookingNo));
    }
  }, [TourBookingNo]);

  return (
    <>
      {TourBookingReportDetails && (
        <div className="container-fluid" style={{ fontSize: 12 }}>
          <div className="row">
            <div className="col-12 p-3">
              <table className="w-100 border border-dark">
                <tr className="border border-dark">
                  <td className="w-50 p-2">
                    <span className="fw-600">Tour Booking No.</span> :{" "}
                    {TourBookingReportDetails?.TourBookingNo}
                  </td>
                  <td className="w-50 p-2">
                    <span className="fw-600">Book By</span> :{" "}
                    {TourBookingReportDetails?.BookByName}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-2">
                    <div>
                      <span className="fw-600">Tour Name</span> :{" "}
                      {TourBookingReportDetails?.Tour}
                    </div>
                  </td>
                  <td className="p-2">
                    <div>
                      <span className="fw-600">Tour Start - End Date</span> :{" "}
                      {TourBookingReportDetails?.TourStartEndDate}
                    </div>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-2">
                    <div>
                      <span className="fw-600">Tour Date Code</span> :{" "}
                      {TourBookingReportDetails?.TourDateCode}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="d-flex w-100" style={{ gap: "35%" }}>
                      <div>
                        <span className="fw-600">No Of Days</span> :{" "}
                        {TourBookingReportDetails?.NoOfDays}
                      </div>
                      <div>
                        <span className="fw-600">No Of Nights</span> :{" "}
                        {TourBookingReportDetails?.NoOfNights}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="fw-600">Party Name</span> :{" "}
                    {TourBookingReportDetails?.Name}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="fw-600">Party Address</span> :{" "}
                    {TourBookingReportDetails?.Address}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2">
                    <span className="fw-600">Mobile No.</span> :{" "}
                    {TourBookingReportDetails?.MobileNo}
                  </td>
                  <td className=" p-2">
                    <span className="fw-600">Resident Mo. No.</span> :{" "}
                    {TourBookingReportDetails?.ResidentPhoneNo}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2">
                    <span className="fw-600">Email ID</span> :{" "}
                    {TourBookingReportDetails?.EmailID}
                  </td>
                  <td className=" p-2">
                    <span className="fw-600">For Emergency Mo. No.</span> :{" "}
                    {TourBookingReportDetails?.EmergencyNo}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2">
                    <span className="fw-600">Pickup Place</span> :{" "}
                    {TourBookingReportDetails?.PickupPlace}
                  </td>
                  <td className=" p-2">
                    <span className="fw-600">Drop Place</span> :{" "}
                    {TourBookingReportDetails?.DropPlace}
                  </td>
                </tr>
              </table>
            </div>
            <div className="col-12 p-3 pt-0">
              {TourBookingReportDetails?.TourBookingFormPax &&
                TourBookingReportDetails?.TourBookingFormPax.length && (
                  <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                    PASSENGER DETAILS
                  </h6>
                )}
              {TourBookingReportDetails?.SectorType === "DOMESTIC"
                ? TourBookingReportDetails?.TourBookingFormPax &&
                  TourBookingReportDetails?.TourBookingFormPax?.length > 0 && (
                    <table className="w-100 border border-dark">
                      <tr style={{ background: "#DA0000", color: "white" }}>
                        <td className="border border-dark p-2">No</td>
                        <td className="border border-dark p-2">Name</td>
                        <td className="border border-dark p-2">Gender</td>
                        <td className="border border-dark p-2">Age</td>
                      </tr>
                      {TourBookingReportDetails?.TourBookingFormPax?.map(
                        (pax: any, index: number) => (
                          <tr key={index}>
                            <td className="border border-dark p-2">
                              {index + 1}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Name}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Gender}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Age}
                            </td>
                          </tr>
                        )
                      )}
                    </table>
                  )
                : TourBookingReportDetails?.TourBookingFormPax &&
                  TourBookingReportDetails?.TourBookingFormPax?.length > 0 && (
                    <table className="w-100 border border-dark">
                      <tr style={{ background: "#DA0000", color: "white" }}>
                        <td className="border border-dark p-2">No</td>
                        <td className="border border-dark p-2">Name</td>
                        <td className="border border-dark p-2">Gender</td>
                        <td className="border border-dark p-2">Age</td>
                        <td className="border border-dark p-2">Nationality</td>
                        <td className="border border-dark p-2">Passport</td>
                        <td className="border border-dark p-2">Issue Date</td>
                        <td className="border border-dark p-2">Expiry Date</td>
                      </tr>
                      {TourBookingReportDetails?.TourBookingFormPax?.map(
                        (pax: any, index: number) => (
                          <tr key={index}>
                            <td className="border border-dark p-2">
                              {index + 1}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Name}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Gender}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Age}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.Nationality}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.PassportNo}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.IssueDate}
                            </td>
                            <td className="border border-dark p-2">
                              {pax?.ExpiryDate}
                            </td>
                          </tr>
                        )
                      )}
                    </table>
                  )}
            </div>
            <div className="col-12 p-3">
              {TourBookingReportDetails?.TourBookingFormAirTicket &&
                TourBookingReportDetails?.TourBookingFormAirTicket.length >
                  0 && (
                  <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                    AIR TICKET DETAILS
                  </h6>
                )}
              <div className="table-responsive">
                {TourBookingReportDetails?.TourBookingFormAirTicket &&
                TourBookingReportDetails?.TourBookingFormAirTicket.length >
                  0 ? (
                  <table className="w-100">
                    <tbody>
                      <tr
                        className="text-center"
                        style={{ background: "#DA0000", color: "white" }}
                      >
                        <td className="border border-dark p-2">No</td>
                        <td className="border border-dark p-2">
                          Passenger Name
                        </td>
                        <td className="border border-dark p-2">
                          Airline / Flight No
                        </td>
                        <td className="border border-dark p-2">
                          From Place to To Place
                        </td>
                        <td className="border border-dark p-2">
                          Department Date Time - Arrival Date Time
                        </td>
                        <td className="border border-dark p-2">PNR No.</td>
                        <td className="border border-dark p-2">Pax</td>
                        <td className="border border-dark p-2">Boarding</td>
                        <td className="border border-dark p-2">Class</td>
                        {/* <td className="border border-dark p-2">Type</td> 
                      <td className="border border-dark p-2">Seat Number</td> */}
                        <td className="border border-dark p-2">Amount</td>
                        {/* <td className="border border-dark p-2">Conv. Charge</td> 
                      <td className="border border-dark p-2">GST</td> 
                      <td className="border border-dark p-2">Invoice Amount</td> 
                      <td className="border border-dark p-2">
                        Vendor Cancellation
                      </td>
                      <td className="border border-dark p-2">
                        Our Cancellation
                      </td>
                      <td className="border border-dark p-2">
                        Cancellation GST
                      </td>
                      <td className="border border-dark p-2">
                        Credit Note Cost
                      </td> */}
                        <td className="border border-dark p-2">Remarks</td>
                        <td className="border border-dark p-2">Status</td>
                        {/* <td className="border border-dark p-2">Booking From</td> */}
                        <td className="border border-dark p-2">Vendor Name</td>
                        {/* <td className="border border-dark p-2">Approve Ticket</td> */}
                      </tr>
                      {TourBookingReportDetails?.TourBookingFormAirTicket.map(
                        (airTicket: any, index: number) => (
                          <tr key={index} className="text-center">
                            <td className=" border border-dark p-2">
                              {index + 1}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.PassengerName}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.Airline + " / " + airTicket.FlightNo}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.FromPlace + " to " + airTicket.ToPlace}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.DepartureDateTime +
                                " - " +
                                airTicket.ArrivalDateTime}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.PNRNo}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.Pax}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.Boarding}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.Class}
                            </td>
                            {/* <td className=" border border-dark p-2">
                            {airTicket.Type}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.SeatNo}
                          </td> */}
                            <td className=" border border-dark p-2">
                              {airTicket.BasicAmount}
                            </td>
                            {/* <td className=" border border-dark p-2">
                            {airTicket.ConvenienceCharge}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.GST}
                          </td> 
                          <td className=" border border-dark p-2">
                            {airTicket.InvoiceAmount}
                          </td>
                           <td className=" border border-dark p-2">
                            {airTicket.VendorCancellation}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.OurCancellation}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.CancellationGST}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.CreditnoteCost}
                          </td> */}
                            <td className=" border border-dark p-2">
                              {airTicket.Remarks}
                            </td>
                            <td className=" border border-dark p-2">
                              {airTicket.Status}
                            </td>
                            {/* <td className=" border border-dark p-2">
                            {airTicket.BookingFrom}
                          </td> */}
                            <td className=" border border-dark p-2">
                              {airTicket.VendorName}
                            </td>
                            {/* <td className=" border border-dark p-2">
                            {airTicket.ApprovedTicket}
                          </td> */}
                          </tr>
                        )
                      )}
                      <tr>
                        <td
                          colSpan={9}
                          style={{ background: "lightgray", color: "black" }}
                          className=" text-end border border-dark p-2 fw-600"
                        >
                          Total
                        </td>
                        <td
                          style={{ background: "lightgray", color: "black" }}
                          className=" text-center border border-dark p-2 fw-600"
                        >
                          {sumBy(
                            TourBookingReportDetails?.TourBookingFormAirTicket,
                            "BasicAmount"
                          )}
                        </td>
                        {/* <td className="border border-dark p-2"></td>
                      <td className="border border-dark p-2"></td> 
                      <td className=" text-center pr-10 border border-dark p-2">
                        {sumBy(
                          TourBookingReportDetails?.TourBookingFormAirTicket,
                          "InvoiceAmount"
                        )}
                      </td>
                       <td className="border border-dark p-2"></td>
                      <td className="border border-dark p-2"></td>
                      <td className="border border-dark p-2"></td>
                      <td className=" text-right pr-10 border border-dark p-2">
                        {sumBy(
                          TourBookingReportDetails?.TourBookingFormAirTicket,
                          "CreditnoteCost"
                        )}
                      </td>
                      <td className=" border border-dark p-2"></td>
                      <td className=" border border-dark p-2"></td>*/}
                        <td
                          style={{ background: "lightgray", color: "black" }}
                          className=" border border-dark p-2"
                        ></td>
                        <td
                          style={{ background: "lightgray", color: "black" }}
                          className=" border border-dark p-2"
                        ></td>
                        <td
                          style={{ background: "lightgray", color: "black" }}
                          className=" border border-dark p-2"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
            <div className="col-12 p-3">
              <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                PAYMENT SUMMARY
              </h6>
              <table className="w-100 border border-dark">
                {/* <thead>
                <tr className="fw-600 text-center bg-dark">
                  <td className="w-100 text-white" colSpan={2}>
                    {" "}
                    PAYMENT SUMMARY
                  </td>
                </tr>
              </thead> */}
                <tbody>
                  <tr
                    className="fw-600 text-center"
                    style={{ background: "#DA0000", color: "white" }}
                  >
                    <td className="col-6 border border-dark p-2 fw-600 text-center">
                      Description
                    </td>
                    <td className="col-6 border border-dark p-2 fw-600 text-center">
                      Total Cost
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35">
                      Total Cost (A)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingReportDetails?.TourCost -
                        TourBookingReportDetails?.VehicleCost}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Vehicle Cost (B)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingReportDetails?.VehicleCost}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Air / Bus Ticket Cost (C)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingReportDetails?.AirTicketCost}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Tour Cancellation Cost (D)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingReportDetails?.TourCancellationCost}
                    </td>
                  </tr>
                  <tr style={{ background: "antiquewhite" }}>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Tour Booking Amount (E) = (A + B + C + D)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingReportDetails?.TotalBookingAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Total Paid Amount (F)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingReportDetails?.TotalPaidAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35">
                      Refund Amount (G)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10">
                      {TourBookingReportDetails?.RefundAmount}
                    </td>
                  </tr>
                  <tr style={{ background: "#ffdddd" }}>
                    <td className="fw-600 border border-dark p-2 pl-35">
                      Remaining Amount (H) = (E + F + G)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10">
                      {TourBookingReportDetails?.RemainingAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12 p-3">
              <p className="p-2" style={{ border: "1px solid #000" }}>
                <b>Remarks :- </b> {TourBookingReportDetails?.Remarks}
              </p>
            </div>
            <div className="col-12 p-3">
              <div className="p-3 border border-dark">
                <h5>Terms & Conditions:</h5>
                <p style={{ lineHeight: "22px" }}>
                  ◙ The receipt will be valid only after the cheque given by you
                  will is passed. <br />
                  ◙ If you cancel the tour after booking, the cancellation will
                  be as per the rules of the company.
                  <br />
                  ◙ A written notice is required to cancel the tour. And the
                  proof of the reason of cancelling the tour is required.
                  <br />
                  ◙ If more than 4 passengers booked a tour and if any one
                  family cancels the tour cancelled it due to certain
                  circumstances, then the remaining passengers have to continue
                  the tour with other passengers or the loss of the company has
                  to be paid.
                  <br />
                  ◙ Full payment should be made in the account of company before
                  15 days of the tour or it will be assumed that the passengers
                  cancelled the tour.
                  <br />◙ Collect the payment receipt from the office.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TourBookingReport;
