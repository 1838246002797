import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGroupRoute,
  IGroupRouteDetails,
  IGroupRouteState,
} from "./groupRouteModel";
import {
  ActiveInactiveGroupRoute,
  createGroupRoute,
  deleteGroupRoute,
  getAllActiveGroupRoute,
  getAllGroupRoutes,
  getGroupRouteByID,
  getGroupRouteBySectorID,
  updateGroupRoute,
} from "./services/groupRoute.services";

const initialState: IGroupRouteState = {
  loading: false,
  error: "",
  GroupRouteID: null,
  GroupRouteList: [],
  GroupRouteDetail: {
    ID: 0,
    SectorID: null,
    RouteName: "",
    StatDate: "",
    HotelID: "",
    ManagerDetails: "",
    Remarks: "",
    IsActive: 1,
    GroupRouteInfo: [],
    HotelDetailsInfo: [],
  },
  deleteIndex: -1,
};

const groupRouteSlice = createSlice({
  name: "groupRoute",
  initialState,
  reducers: {
    clearGroupRouteDetails: (state) => {
      state.GroupRouteDetail = initialState.GroupRouteDetail;
    },
    setGroupRouteID: (state, action) => {
      state.GroupRouteID = action.payload;
    },
    clearGroupRouteID: (state) => {
      state.GroupRouteID = initialState.GroupRouteID;
    },
    setGroupRouteInfoDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGroupRoutes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllGroupRoutes.fulfilled,
      (state, action: PayloadAction<IGroupRoute[]>) => {
        state.loading = false;
        state.GroupRouteList = action.payload || [];
      }
    );
    builder.addCase(getAllGroupRoutes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GroupRouteList = [];
    });
    builder.addCase(getAllActiveGroupRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveGroupRoute.fulfilled,
      (state, action: PayloadAction<IGroupRoute[]>) => {
        state.loading = false;
        state.GroupRouteList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveGroupRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GroupRouteList = [];
    });
    builder.addCase(createGroupRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createGroupRoute.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createGroupRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateGroupRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateGroupRoute.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGroupRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteGroupRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteGroupRoute.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGroupRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGroupRouteByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGroupRouteByID.fulfilled,
      (state, action: PayloadAction<IGroupRouteDetails>) => {
        state.loading = false;
        state.GroupRouteDetail = action.payload;
      }
    );
    builder.addCase(getGroupRouteByID.rejected, (state, action) => {
      state.loading = false;
      state.GroupRouteDetail = initialState.GroupRouteDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveGroupRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveGroupRoute.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveGroupRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getGroupRouteBySectorID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGroupRouteBySectorID.fulfilled,
      (state, action: PayloadAction<IGroupRoute[]>) => {
        state.loading = false;
        state.GroupRouteList = action.payload;
      }
    );
    builder.addCase(getGroupRouteBySectorID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GroupRouteList = [];
    });
  },
});

export const {
  clearGroupRouteDetails,
  setGroupRouteID,
  clearGroupRouteID,
  setGroupRouteInfoDeleteIndex,
} = groupRouteSlice.actions;
export default groupRouteSlice.reducer;
