// import React from "react";
import { Routes, Route as RouteItem, useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
// import Login from "../features/login/Login";
// import { openDialog } from "../components/dialog/dialogSlice";
// import { useAppDispatch } from "../app/hooks";
import LogIn from "../features/login/Login";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import State from "../features/state/State";
import City from "../features/city/City";
import Region from "../features/region/Region";
import SpecialityType from "../features/specialitytype/SpecialityType";
import HotelBlock from "../features/hotelblock/HotelBlock";
import CreateHotelBlock from "../features/hotelblock/CreateHotelBlock";
import Sector from "../features/sector/Sector";
import RoomType from "../features/roomtype/RoomType";
import VendorType from "../features/vendortype/VendorType";
import MealType from "../features/mealtype/MealType";
import Vendor from "../features/vendor/Vendor";
import CreateVendor from "../features/vendor/CreateVendor";
import Nationality from "../features/nationality/Nationality";
import SectorCity from "../features/sectorcity/SectorCity";
import TermsAndCondition from "../features/termsandcondition/TermsAndCondition";
import Tour from "../features/tour/Tour";
import CreateTour from "../features/tour/CreateTour";
import Company from "../features/company/Company";
import CreateCompany from "../features/company/CreateCompany";
import Gst from "../features/gst/Gst";
import AirlineBlock from "../features/airlineblock/AirlineBlock";
import CreateAirlineBlock from "../features/airlineblock/CreateAirlineBlock";
import Hotel from "../features/hotel/Hotel";
import CreateHotel from "../features/hotel/CreateHotel";
import Currency from "../features/currency/Currency";
import Airline from "../features/airline/Airline";
import CreateAirline from "../features/airline/CreateAirline";
import Airport from "../features/airport/Airport";
import AirlineClass from "../features/airlineclass/AirlineClass";
import VehicleSharingPax from "../features/vehiclesharingpax/VehicleSharingPax";
import FlightPlace from "../features/flightplace/FlightPlace";
import PaxType from "../features/paxtype/PaxType";
import Route from "../features/route/Route";
import Branch from "../features/branch/Branch";
import CreateBranch from "../features/branch/CreateBranch";
import FoodOption from "../features/foodoption/FoodOption";
import GroupRoute from "../features/grouproute/GroupRoute";
import CreateGroupRoute from "../features/grouproute/CreateGroupRoute";
import CreateTourBooking from "../features/tourbooking/CreateTourBooking";
import TourBooking from "../features/tourbooking/TourBooking";
import HotelVoucher from "../features/hotelvoucher/HotelVoucher";
import CreateHotelVoucher from "../features/hotelvoucher/CreateHotelVoucher";
import PaymentReceipt from "../features/paymentreceipt/PaymentReceipt";
import CreatePaymentReceipt from "../features/paymentreceipt/CreatePaymentReceipt";
import RouteVoucher from "../features/routevoucher/RouteVoucher";
import CreateRouteVoucher from "../features/routevoucher/CreateRouteVoucher";
import RoomCategory from "../features/roomcategory/RoomCategory";
import HotelMapping from "../features/hotelmapping/HotelMapping";
import RippleButton from "../components/common/RippleButton";
import Dashboard from "../features/dashboard/Dashboard";
import AirlineVoucher from "../features/airlinevoucher/AirlineVoucher";
import CreateAirlineVoucher from "../features/airlinevoucher/CreateAirlineVoucher";
import CompanyWiseBookingReportForm from "../features/reports/reportsform/CompanyWiseBookingReportForm";
import UserWiseBookingReportForm from "../features/reports/reportsform/UserWiseBookingReportForm";
import HotelBlockRoomStatusReportForm from "../features/reports/reportsform/HotelBlockRoomStatusReportForm";
import CustomerReportForm from "../features/reports/reportsform/CustomerReportForm";
import TourBookingReport from "../features/reports/reportsform/TourBookingReport";
import PaymentReceiptCustomerReport from "../features/reports/reportsform/PaymentReceiptCustomerReport";
import Menu from "../features/menu/Menu";
import Rights from "../features/rights/Rights";
import TourReportForm from "../features/reports/reportsform/TourReportForm";
import TourBookingInvoiceForm from "../features/reports/reportsform/TourBookingInvoiceForm";
import TourBookingInvoiceView from "../features/reports/reportsform/TourBookingInvoiceView";
import AirlineBlockView from "../features/reports/reportsform/AirlineBlockView";
import UserType from "../features/userType/UserType";
import CreateUserType from "../features/userType/CreateUserType";
import CompanyWiseBookingReportView from "../features/reports/reportsform/CompanyWiseBookingReportView";
import UserWiseBookingReportView from "../features/reports/reportsform/UserWiseBookingReportView";
import HotelBlockRoomStatusReportView from "../features/reports/reportsform/HotelBlockRoomStatusReportView";
import CustomerReportView from "../features/reports/reportsform/CustomerReportView";
import TourReportView from "../features/reports/reportsform/TourReportView";
import ManageLedgerForm from "../features/reports/reportsform/ManageLedgerForm";
import ManageLedgerView from "../features/reports/reportsform/ManageLedgerView";
import PrivateRoute from "./PrivateRoute";
// import GroupRoutePrint from "../features/grouproute/GroupRoutePrint";
import ChangePassword from "../features/login/ChangePassword";
import CompanyWisePendingPaymentReportView from "../features/reports/reportsform/CompanyWisePendingPaymentReportView";
import CompanyPendingPaymentReportForm from "../features/reports/reportsform/CompanyWisePendingPaymentReportForm";
import VehicleAllotmentReportForm from "../features/reports/reportsform/VehicleAllotmentReportForm";
import VehicleAllotmentReportView from "../features/reports/reportsform/VehicleAllotmentReportView";
import TotalAirlineVoucher from "../features/dashboard/dashboarinner/TotalAirlineVoucher";
import TotalPendingPayment from "../features/dashboard/dashboarinner/TotalPendingPayment";
import TotalDeparture from "../features/dashboard/dashboarinner/TotalDeparture";
import KasarReportForm from "../features/reports/reportsform/KasarReportForm";
import GroupRoutePrinttwo from "../features/grouproute/GroupRoutePrinttwo";

const MainRouter = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      <RouteItem path="login" element={<LogIn />} />
      <RouteItem
        path="paymentreceiptcustomerview/:ReceiptNo"
        element={<PaymentReceiptCustomerReport />}
      />
      <RouteItem
        path="tourbookinginvoice/:TourBookingNo"
        element={<TourBookingInvoiceView />}
      />
      <RouteItem
        path="companywisebookingreportview/:CompanyID/:DateType/:Type/:TourID/:TourDate/:FromDate/:ToDate"
        element={<CompanyWiseBookingReportView />}
      />
      <RouteItem
        path="companywisependingpaymentreportview/:CompanyID/:DateType/:Type/:TourID/:FromDate/:ToDate"
        element={<CompanyWisePendingPaymentReportView />}
      />
      <RouteItem
        path="vehicleallotmentreportview/:TourDateCode"
        element={<VehicleAllotmentReportView />}
      />
      <RouteItem
        path="userwisebookingreportview/:UserID/:TourID/:FromDate/:ToDate"
        element={<UserWiseBookingReportView />}
      />
      <RouteItem
        path="hotelblockroomstatusview/:TourID/:TourDate"
        element={<HotelBlockRoomStatusReportView />}
      />
      <RouteItem
        path="customerreportview/:DateType/:TourID/:TourDate/:FromDate/:ToDate"
        element={<CustomerReportView />}
      />
      <RouteItem
        path="tourreportview/:TourID/:NoOfNights/:TourDate/:FromDate/:ToDate"
        element={<TourReportView />}
      />
      <RouteItem
        path="tourbookingdetails/:TourBookingNo"
        element={<TourBookingReport />}
      />
      <RouteItem
        path="ledgerreportview/:BookingNo/:CompanyID"
        element={<ManageLedgerView />}
      />
      {/* <RouteItem
        path="grouproute/:GroupRouteID"
        element={<GroupRoutePrint />}
      /> */}
       <RouteItem
        path="grouproute/:GroupRouteID"
        element={<GroupRoutePrinttwo />}
      />

      <RouteItem
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <RouteItem index={true} element={<Dashboard />} />
        <RouteItem
          path="dashboard/totalairlinevoucher"
          element={<TotalAirlineVoucher />}
        />
        <RouteItem
          path="dashboard/pendingpayment"
          element={<TotalPendingPayment />}
        />
        <RouteItem
          path="dashboard/totaldeparture"
          element={<TotalDeparture />}
        />
        <RouteItem path="changepassword" element={<ChangePassword />} />
        <RouteItem path="menu" element={<Menu />} />
        <RouteItem path="rights" element={<Rights />} />
        <RouteItem path="country" element={<Country />} />
        <RouteItem path="state" element={<State />} />
        <RouteItem path="city" element={<City />} />
        <RouteItem path="region" element={<Region />} />
        <RouteItem path="sector" element={<Sector />} />
        <RouteItem path="sectorcity" element={<SectorCity />} />
        <RouteItem path="roomtype" element={<RoomType />} />
        <RouteItem path="mealtype" element={<MealType />} />
        <RouteItem path="vendor" element={<Vendor />} />
        <RouteItem path="vendor/create" element={<CreateVendor />} />
        <RouteItem path="vendor/edit" element={<CreateVendor />} />
        <RouteItem path="vendortype" element={<VendorType />} />
        <RouteItem path="specialitytype" element={<SpecialityType />} />
        <RouteItem path="nationality" element={<Nationality />} />
        <RouteItem path="gst" element={<Gst />} />
        <RouteItem path="termsandcondition" element={<TermsAndCondition />} />
        <RouteItem path="user" element={<User />} />
        <RouteItem path="user/create" element={<CreateUser />} />
        <RouteItem path="user/edit" element={<CreateUser />} />
        <RouteItem path="hotelblock" element={<HotelBlock />} />
        <RouteItem path="hotelblock/create" element={<CreateHotelBlock />} />
        <RouteItem path="hotelblock/edit" element={<CreateHotelBlock />} />
        <RouteItem path="tour" element={<Tour />} />
        <RouteItem path="tour/create" element={<CreateTour />} />
        <RouteItem path="tour/edit" element={<CreateTour />} />
        <RouteItem path="company" element={<Company />} />
        <RouteItem path="company/create" element={<CreateCompany />} />
        <RouteItem path="company/edit" element={<CreateCompany />} />
        <RouteItem path="airlineblock" element={<AirlineBlock />} />
        <RouteItem
          path="airlineblock/create"
          element={<CreateAirlineBlock />}
        />
        <RouteItem path="airlineblock/edit" element={<CreateAirlineBlock />} />
        <RouteItem path="hotel" element={<Hotel />} />
        <RouteItem path="hotel/create" element={<CreateHotel />} />
        <RouteItem path="hotel/edit" element={<CreateHotel />} />
        <RouteItem path="currency" element={<Currency />} />
        <RouteItem path="airline" element={<Airline />} />
        <RouteItem path="airline/create" element={<CreateAirline />} />
        <RouteItem path="airline/edit" element={<CreateAirline />} />
        <RouteItem path="airport" element={<Airport />} />
        <RouteItem path="airlineclass" element={<AirlineClass />} />
        <RouteItem path="vehiclesharingpax" element={<VehicleSharingPax />} />
        <RouteItem path="flightplace" element={<FlightPlace />} />
        <RouteItem path="paxtype" element={<PaxType />} />
        <RouteItem path="route" element={<Route />} />
        <RouteItem path="branch" element={<Branch />} />
        <RouteItem path="branch/create" element={<CreateBranch />} />
        <RouteItem path="branch/edit" element={<CreateBranch />} />
        <RouteItem path="foodoption" element={<FoodOption />} />
        <RouteItem path="grouproute" element={<GroupRoute />} />
        <RouteItem path="grouproute/create" element={<CreateGroupRoute />} />
        <RouteItem path="grouproute/edit" element={<CreateGroupRoute />} />
        <RouteItem path="tourbooking" element={<TourBooking />} />
        <RouteItem path="tourbooking/create" element={<CreateTourBooking />} />
        <RouteItem path="tourbooking/edit" element={<CreateTourBooking />} />
        <RouteItem path="hotelvoucher" element={<HotelVoucher />} />
        <RouteItem
          path="hotelvoucher/create"
          element={<CreateHotelVoucher />}
        />
        <RouteItem path="hotelvoucher/edit" element={<CreateHotelVoucher />} />
        <RouteItem path="paymentreceipt" element={<PaymentReceipt />} />
        <RouteItem
          path="paymentreceipt/create"
          element={<CreatePaymentReceipt />}
        />
        <RouteItem
          path="paymentreceipt/edit"
          element={<CreatePaymentReceipt />}
        />
        <RouteItem path="routevoucher" element={<RouteVoucher />} />
        <RouteItem
          path="routevoucher/create"
          element={<CreateRouteVoucher />}
        />
        <RouteItem path="routevoucher/edit" element={<CreateRouteVoucher />} />
        <RouteItem path="roomcategory" element={<RoomCategory />} />
        <RouteItem path="hotelmapping" element={<HotelMapping />} />
        <RouteItem path="airlinevoucher" element={<AirlineVoucher />} />
        <RouteItem
          path="airlinevoucher/create"
          element={<CreateAirlineVoucher />}
        />
        <RouteItem
          path="airlinevoucher/edit"
          element={<CreateAirlineVoucher />}
        />
        <RouteItem
          path="companywisebookingreportform"
          element={<CompanyWiseBookingReportForm />}
        />
        <RouteItem
          path="companywisependingpaymentreportform"
          element={<CompanyPendingPaymentReportForm />}
        />
        <RouteItem
          path="vehicleallotmentreportform"
          element={<VehicleAllotmentReportForm />}
        />
        <RouteItem
          path="userwisetourbookingreportform"
          element={<UserWiseBookingReportForm />}
        />
        <RouteItem
          path="hotelblockroomstatusform"
          element={<HotelBlockRoomStatusReportForm />}
        />
        <RouteItem path="customerreportform" element={<CustomerReportForm />} />
        <RouteItem path="tourreportform" element={<TourReportForm />} />
        <RouteItem
          path="tourbookinginvoiceform"
          element={<TourBookingInvoiceForm />}
        />
        <RouteItem path="airlineblockview" element={<AirlineBlockView />} />
        <RouteItem path="ledgerreportform" element={<ManageLedgerForm />} />
        <RouteItem path="kasarreportform" element={<KasarReportForm />} />
        <RouteItem path="usertype" element={<UserType />} />
        <RouteItem path="usertype/create" element={<CreateUserType />} />
        <RouteItem path="usertype/edit" element={<CreateUserType />} />
      </RouteItem>
      <RouteItem
        path="*"
        element={
          <RippleButton
            onClick={() => navigate("/")}
            style={{ margin: "20px" }}
          >
            Back to Home
          </RippleButton>
        }
      />
    </Routes>
  );
};

export default MainRouter;
